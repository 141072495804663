import { ChangeItemProcessStatus } from './../course-items.actions';
import { CourseHierarchyItemTypeEnum } from 'src/app/components/user-dashboard/product/models/course';
import {
    CreateBulQuestionActions,
  CreateQuizQuestionActions,
  DeleteQuizQuestionActions,
  MoveQuizQuestionActions,
  UpdateQuizQuestionActions,
} from './course-quiz-items.actions';

// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators'; // Import operators correctly
import { NEVER, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { ApiService } from '../../../../../../services';
import { MoveCourseItemActions } from '../../course.actions';

@Injectable()
export class CourseQuizItemsEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) {}

  // Quiz Related
  createQuizQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateQuizQuestionActions.create), // Listen for the loadProducts action
      mergeMap(({ productId, itemId, payload, errorHandler = undefined }) => {
        const itemType = CourseHierarchyItemTypeEnum.quiz;
        this.store.dispatch(
          ChangeItemProcessStatus({
            statusChange: {
              itemId,
              itemType: itemType,
              status: 'creating_question',
            },
          })
        );
        let selectedRequest = this.api.addQuizQuestion(
          productId,
          itemId,
          payload,
          errorHandler
        );
        return selectedRequest.pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            CreateQuizQuestionActions.success({
              updatedItem: response,
              statusChange: { itemId, itemType: itemType, status: 'done' },
            })
          ),
          catchError((error) =>
            of(
              CreateQuizQuestionActions.failure({
                error: { processType: 'creating_question', error },
                statusChange: { itemId, itemType, status: 'error' },
              })
            )
          )
        );
      })
    )
  );


  createBulkQuizQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateBulQuestionActions.create), // Listen for the loadProducts action
      mergeMap(({ productId, itemId, payload, override, errorHandler = undefined }) => {
        const itemType = CourseHierarchyItemTypeEnum.quiz;
        this.store.dispatch(
          ChangeItemProcessStatus({
            statusChange: {
              itemId,
              itemType,
              status: 'creating_question',
            },
          })
        );
        const selectedRequest = this.api.addBulkQuizQuestions(
          productId,
          itemId,
          payload,
          override,
          errorHandler
        );
        return selectedRequest.pipe(
          // Use pipe method correctly within mergeMap
          map((response: any) =>
            CreateQuizQuestionActions.success({
              updatedItem: response,
              statusChange: { itemId, itemType, status: 'done' },
            })
          ),
          catchError((error) =>
            of(
              CreateQuizQuestionActions.failure({
                error: { processType: 'creating_question', error },
                statusChange: { itemId, itemType, status: 'error' },
              })
            )
          )
        );
      })
    )
  );


  updateQuizQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateQuizQuestionActions.update), // Listen for the loadProducts action
      mergeMap(
        ({
          productId,
          itemId,
          questionId,
          payload,
          errorHandler = undefined,
        }) => {
          const itemType = CourseHierarchyItemTypeEnum.quiz;
          this.store.dispatch(
            ChangeItemProcessStatus({
              statusChange: {
                itemId,
                itemType: itemType,
                status: 'updating_question',
              },
            })
          );
          let selectedRequest = this.api.updateQuizQuestion(
            productId,
            itemId,
            questionId,
            payload,
            errorHandler
          );
          return selectedRequest.pipe(
            // Use pipe method correctly within mergeMap
            map((response: any) =>
              UpdateQuizQuestionActions.success({
                updatedItem: response,
                statusChange: { itemId, itemType: itemType, status: 'done' },
              })
            ),
            catchError((error) =>
              of(
                UpdateQuizQuestionActions.failure({
                  error: { processType: 'updating_question', error },
                  statusChange: { itemId, itemType, status: 'error' },
                })
              )
            )
          );
        }
      )
    )
  );

  moveQuizQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveQuizQuestionActions.move), // Listen for the loadProducts action
      mergeMap(
        ({
          productId,
          itemId,
          questionId,
          payload,
          errorHandler = undefined,
        }) => {
          const itemType = CourseHierarchyItemTypeEnum.quiz;
          this.store.dispatch(
            ChangeItemProcessStatus({
              statusChange: {
                itemId,
                itemType: itemType,
                status: 'moving_question',
              },
            })
          );
          let selectedRequest = this.api.moveQuizQuestion(
            questionId,
            productId,
            itemId,
            payload,
            errorHandler
          );
          return selectedRequest.pipe(
            // Use pipe method correctly within mergeMap
            map((response: any) =>
              MoveQuizQuestionActions.success({
                updatedItem: response,
                statusChange: { itemId, itemType: itemType, status: 'done' },
              })
            ),
            catchError((error) =>
              of(
                MoveQuizQuestionActions.failure({
                  error: { processType: 'moving_question', error },
                  statusChange: { itemId, itemType, status: 'error' },
                })
              )
            )
          );
        }
      )
    )
  );

  deleteQuizQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteQuizQuestionActions.delete), // Listen for the loadProducts action
      mergeMap(
        ({ productId, itemId, questionId, errorHandler = undefined }) => {
          const itemType = CourseHierarchyItemTypeEnum.quiz;
          this.store.dispatch(
            ChangeItemProcessStatus({
              statusChange: {
                itemId,
                itemType: itemType,
                status: 'deleting_question',
              },
            })
          );
          let selectedRequest = this.api.deleteQuizQuestion(
            productId,
            itemId,
            questionId,
            errorHandler
          );
          return selectedRequest.pipe(
            // Use pipe method correctly within mergeMap
            map((response: any) =>
              DeleteQuizQuestionActions.success({
                updatedItem: response,
                statusChange: { itemId, itemType: itemType, status: 'done' },
              })
            ),
            catchError((error) =>
              of(
                DeleteQuizQuestionActions.failure({
                  error: { processType: 'deleting_question', error },
                  statusChange: { itemId, itemType, status: 'error' },
                })
              )
            )
          );
        }
      )
    )
  );

  updateCourseItemStatusToSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CreateQuizQuestionActions.success,
        UpdateQuizQuestionActions.success,
        DeleteQuizQuestionActions.success,
        MoveQuizQuestionActions.success,
        CreateBulQuestionActions.success
      ), // Listen for the loadProducts action
      mergeMap(({ statusChange }) => {
        return of(ChangeItemProcessStatus({ statusChange }));
      })
    )
  );

  updateCourseItemStatusToError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CreateQuizQuestionActions.failure,
        UpdateQuizQuestionActions.failure,
        DeleteQuizQuestionActions.failure,
        MoveQuizQuestionActions.failure,
        CreateBulQuestionActions.failure
      ), // Listen for the loadProducts action
      mergeMap(({ statusChange, error }) => {
        return of(ChangeItemProcessStatus({ statusChange, error }));
      })
    )
  );
}
