import { MediaLibraryFileModel } from 'src/app/models/sales-funnel';
import { ProductCreationInput } from './product';

import { InstructorItem } from '../../contacts/models/contacts';
import { CustomerItem } from 'src/app/models/system.base.models';

export enum CourseItemStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  DRIP = 'drip',
  LOCKED = 'locked',
}

export interface CourseHierarchy {
  items: CourseHierarchyItem[];
 
}

export class CourseSettingsModel {
  linearProgression: boolean;
}

export class CourseMetaItem {
  name: string;
  description: string;
  icon: string;
  value: string;
}

export interface CourseHierarchyItem {
  id?: number;
  name: string;
  parentId?: number;
  parentName?: string;
  parentType?: CourseHierarchyItemTypeEnum;
  order?: number;
  slot?: number;
  type: CourseHierarchyItemTypeEnum;
  items?: CourseHierarchyItem[];
  // Begin: Shared Properties
  image_id?: number[];
  image?: MediaLibraryFileModel[];
  // Begin: Shared Properties
  status?: CourseItemStatus;
  drippingDays?: number; // Number of days after enrollment for dripping
  dependencyId?: string; // ID of the lesson, quiz, or assignment it depends on
  dependencyItem?: CourseHierarchyItem;
  content?: string;
  createdAt?: number;
  updatedAt?: number;
  previewUrl?: string;
  visitorUrl?: string;
  processing?: boolean;
  disabled?: boolean;
}

export interface CourseHierarchyItemForAI extends CourseHierarchyItem {
  writing?: 'title' | 'description';
  items?: CourseHierarchyItemForAI[];
  content?: string;
}

export enum QuizAssignmentPassingStatus {
  pass = 'pass',
  fail = 'fail',
}
export interface CourseHierarchyItemProgress extends CourseHierarchyItem {
  // Progress-specific properties
  completionStatus?: boolean; // For Every Item
  completionPercentage?: number; // For Every Item
  passingStatus?: QuizAssignmentPassingStatus; //For Quiz and Assignment
  lastScore?: number; //For Quiz and Assignment
  passingAttempts?: number; //For Quiz and Assignment
}

export interface CourseSubModuleItem extends CourseHierarchyItem {}
export interface CourseModuleItem extends CourseHierarchyItem {}

export interface CourseLessonItem extends CourseHierarchyItem {
  lessonType?: LessonTypeEnum;
  content?: string;
  media_id?: number[];
  media?: MediaLibraryFileModel[];
  downloads_id?: number[];
  downloads?: MediaLibraryFileModel[];  
}

export interface CourseAssignmentItem extends CourseHierarchyItem {
  content?: string;
  extensions?: string[];
  uploadLimitSize?: number;
  points?: number;
  autoApprove?: boolean;
  uploadLimitCount?: number;
  deletionEnabled?: boolean;
}

// Quiz

export interface CourseQuizItem extends CourseHierarchyItem {
  media_id?: number[];
  media?: MediaLibraryFileModel[];

  passingPercentage?: number; // from 1 to 100, if not sent then it will be 60
  answerAllQuestionsRequired?: boolean; // default true

  timeLimit?: number; //if 0 then the quiz is not time limited

  showResultPoints?: boolean; //Show result to the student after he finishes the quiz? default false
  showCorrectAnswersCount?: boolean; // default false
  showResultQuizTime?: boolean; // default false

  retriesCount?: number; //If 0 then retries are not allowed,  default 0
  questions?: CourseQuizQuestionItem[];
  questionsRandomOrder?: boolean; //Should the order of questions be randomized? default false
}

export class CourseQuizQuestionItem {
  id?: number;
  order?: number;
  prompt: string = '';
  type: QuizQuestionTypeEnum;
  choices?: Choice[] = []; //if the question type is MCQ the Choices should be here, if it's True/False it would be null
  answer?: boolean; //if the question type is True/False the answer should be here, if it's MCQ it would be null
  correctMsg?: string; //what would be the message if student answered this question correctly, can be null
  incorrectMsg?: string; //what would be the message if student answered this question wrong, can be null
  tipMsg?: string; //tip message for the question, can be null
  points?: number; // default 0

  private determineQuestionType(): QuizQuestionTypeEnum {

    if (this.type) {
      return this.type;
  }

    if (!this.choices || this.choices.length === 0) {
        // throw new Error('Invalid question format: choices are required');
        return QuizQuestionTypeEnum.single;
    }

    const correctAnswers = this.choices.filter((choice) => choice.isCorrect).length;

    if (this.choices.length === 2 && this.choices.every((choice) => 
        choice.value.toLowerCase() === 'true' || choice.value.toLowerCase() === 'false'
    ) && correctAnswers === 1) {
        return QuizQuestionTypeEnum.trueOrFalse;
    }

    if (correctAnswers === 1) {
        return QuizQuestionTypeEnum.single;
    }

    if (correctAnswers > 1) {
        return QuizQuestionTypeEnum.multiple;
    }

    return QuizQuestionTypeEnum.single;

    // throw new Error('Invalid question format: at least one correct answer is required');
}
  constructor(item?: Partial<CourseQuizQuestionItem>) {
    this.id = item?.id;
    this.order = item?.order;
    this.prompt = item?.prompt || '';
    this.type = item?.type;
    this.choices = item?.choices || [];
    this.answer = item?.answer;
    this.correctMsg = item?.correctMsg;
    this.incorrectMsg = item?.incorrectMsg;
    this.tipMsg = item?.tipMsg;
    this.points = item?.points;

    
    this.type = this.determineQuestionType();
  }

}

export class Choice {
  id?: number;
  value: string;
  isCorrect: boolean;
  isChosen?: boolean;
}

export class QuestionType {
  label: string;
  description?: string;
  icon: string;
  value: QuizQuestionTypeEnum;
}

export enum QuizQuestionTypeEnum {
  single = 'single',
  multiple = 'multiple',
  trueOrFalse = 'trueOrFalse',
}

export enum LessonTypeEnum {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export enum CourseHierarchyItemTypeEnum {
  module = 'module',
  subModule = 'subModule',
  lesson = 'lesson',
  quiz = 'quiz',
  assignment = 'assignment',
}

// export enum QuizQuestionTypeEnum {
//   MCQ = 'MCQ',
//   TF = 'TF'
// }



export class CourseCreationInput extends ProductCreationInput {}

// Begin: Course Items Edit Models


export interface ModuleEditInput {
  name: string;
}

export interface SubModuleEditInput {
  name: string;
  image_id: number[];
  description: string;
}
export interface LessonEditInput {
  name?: string;
  image_id?: number[];
  media_id?: number[];
  content?: string;
  downloads?: string[];
}

export interface AssignmentEditInput {
  name: string;
  content: string;
  extensions?: string[];
  uploadLimitSize?: number;
  points?: number;
  autoApprove?: boolean;
  uploadLimitCount?: number;
  deletionEnabled?: boolean;
}

export interface QuizSettingsEditInput {
  passingPercentage?: number;
  answerAllQuestionsRequired?: boolean;
  timeLimit?: number;
}

export interface QuizEditInput extends QuizSettingsEditInput {
  name?: string;
  image_id?: number[];
}

// End: Course Items Edit Models

// Begin: Course Items Creation Models
export class CreateModuleInput {
  name: string;
  order: number;
}

export class CreateSubModuleInput extends CreateModuleInput {
  parentId: number;
}

export class CreateLessonInput extends CreateSubModuleInput {
  parentType: CourseHierarchyItemTypeEnum;
}

export class CreateQuizInput extends CreateLessonInput {}

export class CreateAssignmentInput extends CreateLessonInput {}


export class GenericCourseItemCreationInput extends CreateModuleInput {
  parentId: number;
  parentType: CourseHierarchyItemTypeEnum;

}
// End: Course Items Creation Models


export class SetCourseHeirarchyInput {
  presetId?: number;
  hierarchy: CourseHierarchyItemForAI[]
}

// Course Rearrange
export class SetLessonAsPreviewInput {
  public lessonId: number;
  public status: boolean;
}

export class MoveCourseItemInput {
  public id: number;
  public itemType: CourseHierarchyItemTypeEnum;
  public parentId?: number;
  public parentType?: CourseHierarchyItemTypeEnum;
  public order: number;
}

export class ChangeCourseItemStatusInput {
  id: number;
  itemType: CourseHierarchyItemTypeEnum;
  status: CourseItemStatus;
  drippingDays?: number;
  dependencyId?: number;
  dependencyType?: CourseHierarchyItemTypeEnum;
}
export class DeleteCourseItemInput {
  id: number;
  itemType: CourseHierarchyItemTypeEnum;
}

// Quiz Rearrange
export class MoveQuizQuestionItemInput {
  public order: number;
}

// Students List
export interface StudentsList {
  students: StudentItem[];
}





export class StudentItem extends CustomerItem {
  lastActivity: { time: number; activity: string };
  isDemo: boolean;
  progress: number;
}

export interface QuizResultsResponse {
  quizzes: QuizResult[];
}

export interface QuizResult {
  passed: boolean;
  score: number;
  points: number;
  timeSpent: number;
  percentage: number;
  student: StudentItem;
  attemptsCount: number;
  questions: QuestionResult[];
}

export interface QuestionResult {
  id: number;
  prompt: string;
  type: string;
  answeredCorrectly: boolean;
  choices: Choice[];
}




export class CourseHeirarchyModelBase {
  public settings?: CourseSettingsModel;
  public heirarchy_status?: string;
  public heirarchy_source?: string;
  public usage_count?: {ai_templates?: number, presets?: number};
}

export class CourseHeirarchyModel extends CourseHeirarchyModelBase {
  public hierarchy: CourseHierarchy;
  
}



// ------------------------- Course Details -----------------------
export class CourseDetailsModelBase {
  categories: Partial<CourseCategoryItem>[];
  extraMetas: Partial<CourseExtraMetaModel>;
  features: CourseFeatureItem[];
  instructors: CourseInstructorItem[];
}

export class CourseInstructorItem extends InstructorItem {
  
}
export interface CourseFeatureItem {
  icon: string;
  id: string;
  key: string;
  value: string;
}

export interface CourseCategoryItem {
  id: number;
  name: string;
}

export interface CourseExtraMetaModel {
  duration: {value?: number, unit?: string} | null;
  language: string | null;
  // categories: string[];
  level: string | null;
}