import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3
}

interface LogEntry {
  timestamp: Date;
  level: LogLevel;
  message: string;
  payload?: any;
  context?: any;
}

interface LoggingConfig {
  logToConsole: boolean;
  // logToServer: boolean;
  // serverEndpoint: string;
  minLogLevel: LogLevel;
  enableRouteTracking: boolean;
  enablePerformanceTracking: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private logBuffer: LogEntry[] = [];
  private config: LoggingConfig = {
    logToConsole: isDevMode(),
    // logToServer: false,
    // serverEndpoint: 'https://api.yourdomain.com/logs',
    minLogLevel: isDevMode() ? LogLevel.DEBUG : LogLevel.INFO,
    enableRouteTracking: true,
    enablePerformanceTracking: true
  };
  
  private performanceEntries = new Map<string, number>();

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.setupRouterTracking();
  }

  public debug(message: string, payload?: any) {
    this.log(LogLevel.DEBUG, message, payload);
  }

  public info(message: string, payload?: any) {
    this.log(LogLevel.INFO, message, payload);
  }

  public warn(message: string, payload?: any) {
    this.log(LogLevel.WARN, message, payload);
  }

  public error(message: string, error?: any) {
    const payload = error instanceof Error ? 
      { message: error.message, stack: error.stack } : 
      error;
    this.log(LogLevel.ERROR, message, payload);
  }

  public logUrl(url: string) {
    if (!this.config.enableRouteTracking) { return; }
    
    try {
      const parsedUrl = new URL(url);
      const logData = {
        path: parsedUrl.pathname,
        routeParams: parsedUrl.searchParams.toString(),
        hash: parsedUrl.hash
      };
      
      this.info('Navigation to URL', logData);
    } catch (error) {
      this.error('Error parsing URL', error);
    }
  }

  public timeLog(label: string) {
    if (!this.config.enablePerformanceTracking) { return; }

    if (this.performanceEntries.has(label)) {
      const startTime = this.performanceEntries.get(label)!;
      const duration = performance.now() - startTime;
      this.debug(`Timing for ${label}`, { duration: `${duration.toFixed(2)}ms` });
      this.performanceEntries.delete(label);
    } else {
      this.performanceEntries.set(label, performance.now());
    }
  }

  // public crashReport(error: any, context?: any) {
  //   const entry: LogEntry = {
  //     timestamp: new Date(),
  //     level: LogLevel.ERROR,
  //     message: 'CRASH_REPORT',
  //     payload: {
  //       error: this.serializeError(error),
  //       userAgent: navigator?.userAgent,
  //       platform: navigator?.platform,
  //       context
  //     }
  //   };

  //   this.sendToExternalService([entry]);
  // }

  private log(level: LogLevel, message: string, payload?: any) {
    if (level < this.config.minLogLevel) { return; }

    const entry: LogEntry = {
      timestamp: new Date(),
      level,
      message,
      payload: this.sanitize(payload),
      context: {
        route: window.location.pathname,
        user: this.getUserContext()
      }
    };

    if (this.config.logToConsole) {
      this.consoleLog(level, entry);
    }

    // if (this.config.logToServer) {
    //   this.logBuffer.push(entry);
    //   if (this.logBuffer.length >= 5) {
    //     this.flushLogs();
    //   }
    // }
  }

  private consoleLog(level: LogLevel, entry: LogEntry) {
    const styles = {
      [LogLevel.DEBUG]: 'color: #4CAF50; background: #000;',
      [LogLevel.INFO]: 'color: #2196F3; background: #000;',
      [LogLevel.WARN]: 'color: #FF9800; background: #000;',
      [LogLevel.ERROR]: 'color: #f44336; background: #000;'
    };

    console.log(
      `%c[${LogLevel[level]}] ${entry.timestamp.toISOString()}`,
      styles[level],
      entry.message,
      entry.payload
    );
  }

  private setupRouterTracking() {
    if (this.config.enableRouteTracking) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          this.logUrl((event as NavigationEnd).url);
        });
    }
  }

  // private flushLogs() {
  //   const logsToSend = [...this.logBuffer];
  //   this.logBuffer = [];
    
  //   this.sendToExternalService(logsToSend).catch((error) => {
  //     console.error('Failed to send logs:', error);
  //     this.logBuffer.unshift(...logsToSend);
  //   });
  // }

  // private async sendToExternalService(logs: LogEntry[]) {
  //   try {
  //     await this.http.post(this.config.serverEndpoint, logs).toPromise();
  //   } catch (error) {
  //     throw this.serializeError(error);
  //   }
  // }

  private sanitize(data: any): any {
    try {
      return JSON.parse(JSON.stringify(data, (key, value) => 
        typeof value === 'bigint' ? value.toString() : value
      ));
    } catch (error) {
      return 'Unable to sanitize data';
    }
  }

  private serializeError(error: any): any {
    if (error instanceof Error) {
      return {
        name: error.name,
        message: error.message,
        stack: error.stack
      };
    }
    return error;
  }

  private getUserContext() {
    // Implement your user context retrieval logic
    return { 
      userId: 'anonymous',
      sessionStart: new Date().toISOString()
    };
  }

  // For testing purposes
  public testLogging() {
    this.debug('Debug test', { component: 'PrelineComponent', status: 'init' });
    this.info('Info test', { route: '/dashboard' });
    this.warn('Warning test', { missingDeps: ['HSStaticMethods'] });
    this.error('Error test', new Error('Test error'));
  }
}
