import { SystemProcessLoadingStatus, SystemTypedError } from 'src/app/models/global-classes';


// actions/products.actions.ts
import { createAction, createActionGroup, props } from '@ngrx/store';
import { CourseItemProcessChangePayload } from '../course-items.reducer';
import { CourseQuizItem, CourseQuizQuestionItem, MoveQuizQuestionItemInput, QuizEditInput } from 'src/app/components/user-dashboard/product/models/course';



// Quiz Specific Actions
export const CreateQuizQuestionActions = createActionGroup({
  source: 'Create Quiz Question Actions',
  events: {
    create: props<{
      productId: number,
      itemId: number,
      payload: CourseQuizQuestionItem,
      errorHandler?: any
    }>(),
    success: props<{ updatedItem: CourseQuizItem, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});


export const CreateBulQuestionActions = createActionGroup({
  source: 'Create Quiz Bulk Questions Actions',
  events: {
    create: props<{
      productId: number,
      itemId: number,
      payload: CourseQuizQuestionItem[],
      override: boolean
      errorHandler?: any
    }>(),
    success: props<{ updatedItem: CourseQuizItem, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});

export const UpdateQuizQuestionActions = createActionGroup({
  source: 'Update Quiz Question Actions',
  events: {
    update: props<{
      productId: number,
      itemId: number,
      questionId: number,
      payload: CourseQuizQuestionItem,
      errorHandler?: any
    }>(),
    success: props<{ updatedItem: QuizEditInput, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});

export const DeleteQuizQuestionActions = createActionGroup({
  source: 'Delete Quiz Question Actions',
  events: {
    delete: props<{
      productId: number,
      itemId: number,
      questionId: number,
      errorHandler?: any
    }>(),
    success: props<{ updatedItem: QuizEditInput, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});

export const MoveQuizQuestionActions = createActionGroup({
  source: 'Move Quiz Question Actions',
  events: {
    move: props<{
      productId: number,
      itemId: number,
      questionId: number,
      payload: MoveQuizQuestionItemInput,
      errorHandler?: any
    }>(),
    success: props<{ updatedItem: QuizEditInput, statusChange: CourseItemProcessChangePayload }>(),
    failure: props<{ error: SystemTypedError, statusChange: CourseItemProcessChangePayload }>(),
  },
});

