import { CrashReportsService } from './crash-reports.service';
import { CreateOrUpdateBlogPostDTO } from './../../components/user-dashboard/portal/view/portal-blog/portal-blog-posts/create-blog-post-popup/dto/create-blog.dto';
import {
  OfferCreationDTO,
  OfferEditPayload,
  OfferStausEnum,
} from './../../components/user-dashboard/offer/models/offer';
import {
  SetWebsiteBrandingRequestBody,
  SetWebsiteIdentityRequestBody,
} from './../../components/user-dashboard/portal/portal.model';
import {
  PeriodTypeEnum,
  PeriodTypeValue,
  RefundActionEnum,
} from './../../models/owner-dashboard';

import {
  CreateFunnelInput,
  EditFunnelRequest,
  FunnelSequenceObject,
  OwnerFunnelEmail,
  OwnerFunnelPage,
} from './../../models/funnels';
import {
  UpdateProfileRequest,
  UpdateUserTwoFaPayload,
} from '../../models/user';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { of, throwError, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CreateWebsiteInput } from '../../components/user-dashboard/portal/dto/portal.input';
import { RegisterModel } from '../../models/register';
import { RateTypeEnum } from '../../models/payment.model';

import {
  StripePaymentGatewayModel,
  BtcpayPaymentGatewayModel,
  PaypalPaymentGatewayModel,
  PaymentGatewaysIntegration,
  Payment_Gateway_Enum,
  PortalSenderEmailInput,
  PageItemStatusEnum,
} from '../../models/portal';
import Swal from 'sweetalert2';
import {
  PeriodResultModel,
  CreateAnnouncementRequest,
} from '../../models/owner-dashboard';
import {
  AffiliateLinkMinimizedModel,
  PeriodTypeModel,
} from '../../models/owner-dashboard';
import { SystemEarningsFilter } from 'src/app/models/system-earnings';
import { BillingLogFilter } from 'src/app/models/billing-log';
import {
  ProductCreateModel,
  ProductItem,
} from 'src/app/components/user-dashboard/product/models/product';
import {
  AssignmentEditInput,
  ChangeCourseItemStatusInput,
  CourseCreationInput,
  CourseDetailsModelBase,
  CourseHierarchyItemTypeEnum,
  CourseQuizQuestionItem,
  CourseSettingsModel,
  CreateAssignmentInput,
  CreateLessonInput,
  CreateModuleInput,
  CreateQuizInput,
  CreateSubModuleInput,
  DeleteCourseItemInput,
  GenericCourseItemCreationInput,
  LessonEditInput,
  ModuleEditInput,
  MoveCourseItemInput,
  MoveQuizQuestionItemInput,
  QuizEditInput,
  SetCourseHeirarchyInput,
  SetLessonAsPreviewInput,
  SubModuleEditInput,
} from 'src/app/components/user-dashboard/product/models/course';
import { MediaLibraryCategoryEnum } from 'src/app/models/sales-funnel';
import { TechEarningsCategoryEnum } from 'src/app/components/user-dashboard/owner-earnings/earnings-tech/earnings-tech.component';
import Gleap from 'gleap';
import { LoginMethodsEnum } from 'src/app/components/auth/login-alt/user-login-form-alt/user-login-form-alt.component';
import { CategoryUpdateDto } from 'src/app/components/user-dashboard/product/product-item/course-item/course-edit/course-meta-settings-view/course-category-card/course-category-edit-modal/dto/category-edit.dto';
import { InstructorsUpdateDto } from 'src/app/components/user-dashboard/product/product-item/course-item/course-edit/course-meta-settings-view/course-instructors-card/course-instructors-edit-modal/dto/instructors-edit.dto';
import { CreateContactDto } from 'src/app/components/user-dashboard/contacts/create/dto/create-contact.dto';
import { ContactItemType } from 'src/app/components/user-dashboard/contacts/models/contacts';
import { BlogItemStatusEnum } from 'src/app/models/blog.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  public apiUrl;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private crashReportsService: CrashReportsService) {
    if ('api' in environment && 'url' in environment['api']) {
      this.apiUrl = environment['api']['url'];
    }
  }

  preLogin(identity: string, errorHandler: any = undefined) {
    return this.post('auth/pre-login', { identity }, errorHandler);
  }

  setLoginMethodAsDefault(
    method: LoginMethodsEnum,
    errorHandler: any = undefined
  ) {
    return this.post('users/preferred-login', { method }, errorHandler);
  }

  getLoginMethodsStatus(errorHandler: any = undefined) {
    return this.get('users/login-method', {}, errorHandler);
  }

  updateLoginMethodsStatus(
    method: LoginMethodsEnum,
    enabled: boolean = true,
    errorHandler: any = undefined
  ) {
    return this.post('users/login-method', { method, enabled }, errorHandler);
  }

  login(username, password, errorHandler: any = undefined) {
    return this.post('auth/login', { username, password }, errorHandler);
  }

  loginOauth(code: string, state: string, errorHandler: any = undefined) {
    let query = `?code=${code}&state=${state}`;
    return this.post(`auth/api/signin${query}`, {}, errorHandler);
  }

  loginSid(sid, errorHandler: any = undefined) {
    return this.login('__session__', sid, errorHandler);
  }

  login2FA(sc: number, errorHandler: any = undefined) {
    const url = `auth/login/${sc}`;
    return this.post(url, {}, errorHandler);
  }

  resend2FA(userId: number, errorHandler: any = undefined) {
    const url = 'auth/resend-twofa';
    return this.post(
      url,
      {
        userId,
      },
      errorHandler
    );
  }

  verifyCoupone(couponCode: string, errorHandler: any = undefined) {
    const url = 'coupon/verify/' + couponCode;
    return this.post(url, {}, errorHandler);
  }

  register(model: Partial<RegisterModel>, errorHandler: any = undefined) {
    return this.post('auth/register', model, errorHandler);
  }

  captureLead(model: Partial<RegisterModel>, errorHandler: any = undefined) {
    // let params = new HttpParams();
    // for (const key in model) {
    //   if (model.hasOwnProperty(key)) {
    //     params = params.set(key, model[key]);
    //   }
    // }

    return this.post(`users/is-registered-before`, model, errorHandler);
  }

  influencerProducts(errorHandler: any = undefined) {
    return this.get('subscriptions/products/influencer', {}, errorHandler);
  }

  getUserRole(errorHandler: any = undefined) {
    return this.get('user-role/user', {}, errorHandler);
  }

  getTemplates(errorHandler: any = undefined) {
    return this.get('portal/user/portal-templates', {}, errorHandler);
  }

  getFunnels(errorHandler: any = undefined) {
    return this.get('portal/user/portal-funnels', {}, errorHandler);
  }

  getUserCourses(errorHandler: any = undefined) {
    return this.get('users/get-user-courses', {}, errorHandler);
  }

  influencerDashboard(errorHandler: any = undefined) {
    return this.get('influencer/dashboard', {}, errorHandler);
  }

  confirmEmail(email: string, sc: string, errorHandler: any = undefined) {
    const url = `auth/confirm-email?email=${email}&sc=${sc}`;
    return this.get(url, {}, errorHandler);
  }

  updateProfile(req: UpdateProfileRequest, errorHandler: any = undefined) {
    return this.patch('users/update-profile', req, errorHandler);
  }
  updateUserSettings(
    req: UpdateUserTwoFaPayload,
    errorHandler: any = undefined
  ) {
    return this.post('users/update-settings', req, errorHandler);
  }

  completeInfluencerMaterial(id: number, errorHandler: any = undefined) {
    return this.patch(`influencer/material/complete/${id}`, {}, errorHandler);
  }

  downloadInfluencerMaterial(id: number, errorHandler: any = undefined) {
    const url = `influencer/material/download/${id}`;
    return this.download(url, undefined, errorHandler);
  }
  checkWebsiteName(
    name: string,
    tld: string,
    domain_type: 'paid' | 'free' = 'paid',
    errorHandler: any = undefined
  ) {
    return this.post(
      'portal/user/check-domain',
      {
        name,
        tld,
        domain_type,
      },
      errorHandler
    );
  }
  userWebsite(errorHandler: any = undefined) {
    return this.get('portal/user/user-websites', {}, errorHandler);
  }
  deleteWebsite(siteId: string, errorHandler: any = undefined) {
    return this.delete(
      'portal/user/delete-website',
      {
        siteId,
      },
      errorHandler
    );
  }
  createWebsite(
    createWebsiteInput: CreateWebsiteInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      'portal/user/create-website',
      createWebsiteInput,
      errorHandler
    );
  }

  // Website
  getCurrentWebsite(errorHandler: any = undefined) {
    return this.get(`website`, {}, errorHandler);
  }

  // Portals @@@@DEPRECATED@@@@
  getPortalsList(errorHandler: any = undefined) {
    return this.get(`portal/user/portal-list`, {}, errorHandler);
  }

  getPortalById(portalId: number, errorHandler: any = undefined) {
    return this.get(`portal/user/user-websites/${portalId}`, {}, errorHandler);
  }

  getPortalSubscribers(
    portalId: number,
    funnelId: number = undefined,
    errorHandler: any = undefined
  ) {
    return this.get(
      `sales-funnel/forms-data/?portalId=${
        !!portalId ? portalId : ''
      }&funnelId=${!!funnelId ? funnelId : ''}`,
      {},
      errorHandler
    );
  }

  setPortalSendingEmail(
    portalSenderEmailInput: PortalSenderEmailInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `website/sender-email-info`,
      portalSenderEmailInput,
      errorHandler
    );
  }

  markWebsiteOnboardingAsDone(errorHandler: any = undefined) {
    return this.post(`users/website-setup`, { state: 1 }, errorHandler);
  }

  // Funnels
  createFunnel(
    createFunnelInput: CreateFunnelInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      'portal/user/create-funnel',
      createFunnelInput,
      errorHandler
    );
  }

  deleteFunnelById(funnelId: number, errorHandler: any = undefined) {
    return this.delete(`portal/user/owner-funnels/${funnelId}`, errorHandler);
  }

  getFunnelById(funnelId: number, errorHandler: any = undefined) {
    return this.get(`portal/user/owner-funnels/${funnelId}`, {}, errorHandler);
  }

  getSubscribersById(funnelId: number, errorHandler: any = undefined) {
    return this.get(`sales-funnel/forms-data/${funnelId}`, {}, errorHandler);
  }

  getFunnelAvailablePages(funnelId: number, errorHandler: any = undefined) {
    return this.get(`portal/user/funnel-pages/${funnelId}`, {}, errorHandler);
  }

  getPortalFunnels(portalId: number, errorHandler: any = undefined) {
    return this.get(
      `portal/user/owner-funnels?portalId=${portalId}`,
      {},
      errorHandler
    );
  }

  addOfferToFunnel(
    funnelId: number,
    offerId: number,
    errorHandler: any = undefined
  ) {
    return this.post(
      `portal/user/funnel-offer/${funnelId}`,
      { offerId },
      errorHandler
    );
  }

  deleteOfferFromFunnel(funnelId: number, errorHandler: any = undefined) {
    return this.delete(
      `portal/user/funnel-offer/${funnelId}`,
      {},
      errorHandler
    );
  }

  addAutomationToPage(
    funnelId: number,
    pageId: number,
    errorHandler: any = undefined
  ) {
    return this.post(
      `portal/user/funnel-email/${funnelId}`,
      { pageId },
      errorHandler
    );
  }

  deleteAutomationFromFunnel(
    funnelId: number,
    automationId: number,
    errorHandler: any = undefined
  ) {
    return this.delete(
      `portal/user/funnel-email/${funnelId}`,
      { emailId: automationId },
      errorHandler
    );
  }

  deletePageFromFunnel(
    funnelId: number,
    pageId: number,
    errorHandler: any = undefined
  ) {
    return this.delete(
      `portal/user/funnel-page/${funnelId}`,
      { pageId },
      errorHandler
    );
  }

  addBlankPageToFunnel(
    funnelId: number,
    slotId: number,
    errorHandler: any = undefined
  ) {
    return this.post(
      `portal/user/funnel-blank-page/${funnelId}`,
      { slotId },
      errorHandler
    );
  }

  addOptinPageToFunnel(
    funnelId: number,
    slotId: number,
    errorHandler: any = undefined
  ) {
    return this.post(
      `portal/user/funnel-optin-page/${funnelId}`,
      { slotId },
      errorHandler
    );
  }

  getAllFunnels(errorHandler: any = undefined) {
    return this.get(`portal/user/owner-funnels`, {}, errorHandler);
  }

  updateFunnel(
    funnelId: number,
    funnelPayload: EditFunnelRequest,
    errorHandler: any = undefined
  ) {
    return this.put(
      `portal/user/owner-funnels/${funnelId}`,
      funnelPayload,
      errorHandler
    );
  }

  updateFunnelEmail(
    funnelId: number,
    ownerEmailPayload: OwnerFunnelEmail,
    errorHandler: any = undefined
  ) {
    return this.put(
      `portal/user/funnel-email/${funnelId}`,
      ownerEmailPayload,
      errorHandler
    );
  }

  changeFunnelOrder(
    funnelId: number,
    sequence: FunnelSequenceObject[],
    errorHandler: any = undefined
  ) {
    return this.put(
      `portal/user/funnel-order/${funnelId}`,
      { sequence },
      errorHandler
    );
  }

  updateFunnelPage(
    funnelId: number,
    ownerEmailPayload: OwnerFunnelPage,
    errorHandler: any = undefined
  ) {
    return this.put(
      `portal/user/funnel-page/${funnelId}`,
      ownerEmailPayload,
      errorHandler
    );
  }

  // Sales Funnel Calls Start
  // Payment Gateways
  getPaymentGatewaysStatus(errorHandler: any = undefined) {
    return this.get(`website/payment-gateway`, {}, errorHandler);
  }

  getPaymentGatewayStatus(
    gateway: Payment_Gateway_Enum,
    errorHandler: any = undefined
  ) {
    return this.get(`website/payment-gateway/${gateway}`, {}, errorHandler);
  }

  integratePaymentGateways(
    integrationObject: PaymentGatewaysIntegration,
    errorHandler: any = undefined
  ) {
    return this.post(
      `website/payment-gateway/integrate`,
      integrationObject,
      errorHandler
    );
  }

  sendPaypalData(
    data: { authCode: string; sharedId: string; nonce: string; email: string },
    errorHandler: any = undefined
  ) {
    return this.post(`payment/client/credentials`, data, errorHandler);
  }

  generatePaypalIntegrationReferral(
    payload: { retrun_url: string },
    errorHandler: any = undefined
  ) {
    return this.post(`payment/paypal/referal`, payload, errorHandler);
  }

  revokePayment(
    gateway: Payment_Gateway_Enum,
    force = false,
    errorHandler: any = undefined
  ) {
    return this.delete(
      `website/payment-gateway/${gateway}?force=${force}`,
      {},
      errorHandler
    );
  }

  markNotificationsAsRead(
    notificationIds: number[],
    errorHandler: any = undefined
  ) {
    return this.put(
      `notification/set-as-read`,
      {
        notificationIds,
      },
      errorHandler
    );
  }

  // Notifications
  getOwnerNotifications(
    latest: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.get(`owner/notifications?latest=${latest}`, {}, errorHandler);
  }

  // Announcements
  createAnnouncement(
    announcement: CreateAnnouncementRequest,
    errorHandler: any = undefined
  ) {
    return this.post(`owner/announcements`, announcement, errorHandler);
  }
  getMyAnnouncements(errorHandler: any = undefined) {
    return this.get(`owner/announcements`, {}, errorHandler);
  }

  getMyAnnouncementsByAffiliateId(id: number, errorHandler: any = undefined) {
    return this.get(
      `owner/announcements-for-affiliate/${id}`,
      {},
      errorHandler
    );
  }

  getAnnouncementId(id: number, errorHandler: any = undefined) {
    return this.get(`owner/announcements/${id}`, {}, errorHandler);
  }

  // My Affiliates
  getMyAffiliates(
    status: 'joined' | 'invited' | 'rejected' | 'blocked' = undefined,
    errorHandler: any = undefined
  ) {
    return this.get(`owner/affiliates?status=${status}`, {}, errorHandler);
  }

  getAffiliateById(id: number, errorHandler: any = undefined) {
    return this.get(`owner/affiliates/${id}`, {}, errorHandler);
  }

  getAffiliatesTotalTracking(id: number, errorHandler: any = undefined) {
    return this.get(`owner/affiliate-tracking-totals/${id}`, {}, errorHandler);
  }

  updateAffiliateNote(id: number, note: string, errorHandler: any = undefined) {
    return this.put(`owner/affiliate-note/${id}`, { note }, errorHandler);
  }

  inviteAffiliates(
    invitationData: { emails: string[]; affiliateLinkIds: number[] },
    errorHandler: any = undefined
  ) {
    return this.post(`owner/invite-affiliates`, invitationData, errorHandler);
  }

  uploadKajabiCsv(data: FormData, errorHandler: any = undefined) {
    return this.upload('owner/import-kajabi', data, errorHandler);
  }
  resendInvitationToAffiliates(
    invitationData: { emails: string[]; affiliateLinkIds?: number[] },
    errorHandler: any = undefined
  ) {
    return this.post(`owner/reinvite-affiliates`, invitationData, errorHandler);
  }
  // Affiliate Dashboard
  getAffiliateDashboard(errorHandler: any = undefined) {
    return this.get(`owner/dashboard`, {}, errorHandler);
  }

  getTotalTracking(errorHandler: any = undefined) {
    return this.get(`owner/tracking-totals`, {}, errorHandler);
  }

  getTotalTrackingByAffiliateLink(id: number, errorHandler: any = undefined) {
    return this.get(`owner/tracking-totals/${id}`, {}, errorHandler);
  }

  // Payouts
  getPayoutsTotalsData(errorHandler: any = undefined) {
    return this.get(`owner/payouts-totals`, {}, errorHandler);
  }

  getPayoutsExportData(errorHandler: any = undefined) {
    return this.get(`owner/payouts-export-list`, {}, errorHandler);
  }

  exportPayoutsPeriodData(id: number, errorHandler: any = undefined) {
    return this.get(`owner/export-period-payouts/${id}`, {}, errorHandler);
  }

  getPayoutsMoreDetailsData(periodId: number, errorHandler: any = undefined) {
    return this.get(
      `owner/payouts-more-details?periodId=${periodId}`,
      {},
      errorHandler
    );
  }

  getPayoutsLogsData(id: number = null, errorHandler: any = undefined) {
    return this.get(`owner/payouts-logs?periodId=${id}`, {}, errorHandler);
  }

  markPayoutAsProcessed(id: number, errorHandler: any = undefined) {
    return this.post(
      `owner/mark-payout-proccessed/${id}`,
      undefined,
      errorHandler
    );
  }

  getPayoutsSettingsData(errorHandler: any = undefined) {
    return this.get(`owner/payouts-settings`, {}, errorHandler);
  }

  setPayoutsSettingsData(settingsObject: any, errorHandler: any = undefined) {
    return this.post(`owner/payouts-settings`, settingsObject, errorHandler);
  }

  // Refund
  getRefundList(
    filter: { dummy: boolean } = { dummy: false },
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.dummy) {
      filterStructure = filterStructure + '&';
      filterStructure = filterStructure + `dummy=${filter.dummy}`;
    }

    return this.get(
      `owner/refunds` + (!!filterStructure ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getRefundItemById(id: number, errorHandler: any = undefined) {
    return this.get(`owner/refunds/${id}`, {}, errorHandler);
  }

  resolveRefundRequest(
    id: number,
    resolve: RefundActionEnum,
    errorHandler: any = undefined
  ) {
    return this.put(`owner/refunds/${id}`, { resolve }, errorHandler);
  }

  //Contacts
  createContact(
    createPayload: Partial<CreateContactDto>,
    type: ContactItemType,
    errorHandler: any = undefined
  ) {
    return this.post(`contacts/${type}`, createPayload, errorHandler);
  }
  getContacts(
    filter: { dummy: boolean } = { dummy: false },
    errorHandler: any = undefined
  ) {
    return this.get(`contacts`, {}, errorHandler);
  }

  // Earnings

  getAffiliatesLinkEarningsTotalTracking(
    id: number,
    errorHandler: any = undefined
  ) {
    return this.get(`owner/earnings-totals/${id}`, {}, errorHandler);
  }

  getEarningsOverviewStatisticsData(errorHandler: any = undefined) {
    return this.get(`owner/earnings/statistics`, {}, errorHandler);
  }

  getDashboardStatisticsData(
    period: PeriodTypeModel = null,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!period?.value?.startDate) {
      filterStructure = filterStructure + `startDate=${period.value.startDate}`;
    }
    if (!!period?.value?.endDate) {
      filterStructure = filterStructure + `&endDate=${period.value.endDate}`;
    }

    return this.get(
      `owner/dashboard/tracking` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getEarningsTrackingData(errorHandler: any = undefined) {
    return this.get(`owner/earnings-totals`, {}, errorHandler);
  }

  getEarningsAffiliateLinks(
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/affiliate-link-comparison` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getEarningsAffiliateLinksByAffiliateId(
    id: number,
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/affiliate-link-comparison/${id}` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }
  getEarningsAffiliate(
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/affiliates-earnings-comparison` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  // getEarningsSalesLog(
  //   period: PeriodResultModel = null,
  //   errorHandler: any = undefined
  // ) {
  //   return this.get(
  //     `owner/sales-logs` +
  //       (!!period?.period.enum && !!period?.unit.enum
  //         ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
  //         : ``),
  //     {},
  //     errorHandler
  //   );
  // }

  getUserBillingLog(
    filter: BillingLogFilter = null,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.type) {
      filterStructure = filterStructure + '&';
      filterStructure = filterStructure + `type=${filter.type}`;
    }
    if (!!filter?.page) {
      filterStructure = filterStructure + '&';
      filterStructure = filterStructure + `page=${filter.page}`;
    }

    if (!!filter?.itemsPerPage) {
      filterStructure = filterStructure + '&';
      filterStructure = filterStructure + `itemsPerPage=${filter.itemsPerPage}`;
    }

    return this.get(
      `payment/transactions` + (!!filterStructure ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getEarningsLog(
    filter: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.period?.value?.startDate) {
      filterStructure =
        filterStructure + `startDate=${filter.period.value.startDate}`;
    }
    if (!!filter?.period?.value?.endDate) {
      filterStructure =
        filterStructure + `&endDate=${filter.period.value.endDate}`;
    }
    if (!!filter?.period?.by) {
      filterStructure = filterStructure + `&unit=${filter.period.by}`;
    }
    return this.get(
      `owner/earnings/log` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getSaleItemById(id: number, errorHandler: any = undefined) {
    return this.get(`owner/earnings/log/${id}`, {}, errorHandler);
  }

  getEarningsPeriodLimit(errorHandler: any = undefined) {
    return this.get(`owner/earnings/get-period`, {}, errorHandler);
  }

  getGraphSalesByPeriod(
    filter: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.period?.value?.startDate) {
      filterStructure =
        filterStructure + `startDate=${filter.period.value.startDate}`;
    }
    if (!!filter?.period?.value?.endDate) {
      filterStructure =
        filterStructure + `&endDate=${filter.period.value.endDate}`;
    }
    if (!!filter?.period?.by) {
      filterStructure = filterStructure + `&unit=${filter.period.by}`;
    }

    return this.get(
      `owner/earnings/log/graph/by-period` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getGraphSalesByWorldMapData(
    filter: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.period?.value?.startDate) {
      filterStructure =
        filterStructure + `startDate=${filter.period.value.startDate}`;
    }
    if (!!filter?.period?.value?.endDate) {
      filterStructure =
        filterStructure + `&endDate=${filter.period.value.endDate}`;
    }
    if (!!filter?.period?.by) {
      filterStructure = filterStructure + `&unit=${filter.period.by}`;
    }

    return this.get(
      `owner/earnings/log/graph/by-country/sales/map` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getGraphSalesCountByCountry(
    filter: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.period?.value?.startDate) {
      filterStructure =
        filterStructure + `startDate=${filter.period.value.startDate}`;
    }
    if (!!filter?.period?.value?.endDate) {
      filterStructure =
        filterStructure + `&endDate=${filter.period.value.endDate}`;
    }
    if (!!filter?.period?.by) {
      filterStructure = filterStructure + `&unit=${filter.period.by}`;
    }

    return this.get(
      `owner/earnings/log/graph/by-country/sales/count` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getSalesDetailedByCountry(
    filter: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.period?.value?.startDate) {
      filterStructure =
        filterStructure + `startDate=${filter.period.value.startDate}`;
    }
    if (!!filter?.period?.value?.endDate) {
      filterStructure =
        filterStructure + `&endDate=${filter.period.value.endDate}`;
    }
    if (!!filter?.period?.by) {
      filterStructure = filterStructure + `&unit=${filter.period.by}`;
    }

    return this.get(
      `owner/earnings/log/graph/by-country/sales/details` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getAccountSetupSteps(errorHandler: any = undefined) {
    return this.get(`owner/account-setup`, {}, errorHandler);
  }

  // By Tech

  getGraphSalesCountByTech(
    filter: PeriodResultModel = null,
    techCategory: TechEarningsCategoryEnum = TechEarningsCategoryEnum.device,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.period?.value?.startDate) {
      filterStructure =
        filterStructure + `startDate=${filter.period.value.startDate}`;
    }
    if (!!filter?.period?.value?.endDate) {
      filterStructure =
        filterStructure + `&endDate=${filter.period.value.endDate}`;
    }
    if (!!filter?.period?.by) {
      filterStructure = filterStructure + `&unit=${filter.period.by}`;
    }

    return this.get(
      `owner/earnings/log/graph/by-technology/${techCategory}/count` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  getSalesDetailedByTech(
    filter: PeriodResultModel = null,
    techCategory: TechEarningsCategoryEnum = TechEarningsCategoryEnum.device,
    errorHandler: any = undefined
  ) {
    let filterStructure = '?';

    if (!!filter?.period?.value?.startDate) {
      filterStructure =
        filterStructure + `startDate=${filter.period.value.startDate}`;
    }
    if (!!filter?.period?.value?.endDate) {
      filterStructure =
        filterStructure + `&endDate=${filter.period.value.endDate}`;
    }
    if (!!filter?.period?.by) {
      filterStructure = filterStructure + `&unit=${filter.period.by}`;
    }

    return this.get(
      `owner/earnings/log/graph/by-technology/${techCategory}/details` +
        (filterStructure.length > 1 ? filterStructure : ``),
      {},
      errorHandler
    );
  }

  // getEarningsSalesLogDetailed(
  //   period: PeriodResultModel = null,
  //   errorHandler: any = undefined
  // ) {
  //   return this.get(
  //     `owner/sales-logs-detailed` +
  //       (!!period?.period.enum && !!period?.unit.enum
  //         ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
  //         : ``),
  //     {},
  //     errorHandler
  //   );
  // }
  // Affiliate Links
  getMyAffiliateLinks(
    hasAffiliates: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/affiliate-links?hasAffiliates=${!!hasAffiliates}`,
      {},
      errorHandler
    );
  }

  getAffiliateLinkById(id: number, errorHandler: any = undefined) {
    return this.get(`owner/affiliate-links/${id}`, {}, errorHandler);
  }

  getAffiliatePayoutsDetailed(
    id: number,
    dummy: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/affiliates-unpaid-payouts/${id}` +
        (!!dummy ? `?dummy=${dummy}` : ``),
      {},
      errorHandler
    );
  }

  getAffiliatePayoutsLog(
    id: number,
    dummy: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/affiliates-paid-payouts/${id}` +
        (!!dummy ? `?dummy=${dummy}` : ``),
      {},
      errorHandler
    );
  }

  getAffiliateEarningsSalesLogDetailed(
    id: number,
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/sales-logs-detailed-affiliate/${id}` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getAffiliateEarningsVsCommissions(
    id: number,
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/earnings-vs-commissions/${id}` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getAffiliateLinkEarningsChart(
    id: number,
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/affiliate-link-earnings-chart/${id}` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  getAffiliateLinkEarningsSalesLogDetailed(
    id: number,
    period: PeriodResultModel = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      `owner/sales-logs-detailed-affiliate-link/${id}` +
        (!!period?.period.enum && !!period?.unit.enum
          ? `?period=${period?.period.enum}&unit=${period.unit.enum}`
          : ``),
      {},
      errorHandler
    );
  }

  updateAffiliateLinkById(
    id: number,
    affiliateLinkObject: any,
    errorHandler: any = undefined
  ) {
    return this.put(
      `owner/affiliate-links/${id}`,
      affiliateLinkObject,
      errorHandler
    );
  }

  suspendAffiliateLinkById(id: number, errorHandler: any = undefined) {
    return this.put(
      `owner/suspend-affiliate-link/${id}?suspend=true`,
      undefined,
      errorHandler
    );
  }

  resumeAffiliateLinkById(id: number, errorHandler: any = undefined) {
    return this.put(
      `owner/suspend-affiliate-link/${id}?suspend=false`,
      undefined,
      errorHandler
    );
  }

  createAffiliateLink(
    data: AffiliateLinkMinimizedModel,
    errorHandler: any = undefined
  ) {
    return this.post(`owner/affiliate-links`, data, errorHandler);
  }
  // Offer

  updateOffer(
    offerId: number,
    offerObject: OfferEditPayload,
    errorHandler: any = undefined
  ) {
    return this.put(`offer/${offerId}`, offerObject, errorHandler);
  }

  getOffers(status: OfferStausEnum = undefined, errorHandler: any = undefined) {
    return this.get(
      `offer${!!status ? `?status=${status}` : ``}`,
      {},
      errorHandler
    );
  }

  setOfferAsDefault(offerId: number, errorHandler: any = undefined) {
    return this.put(`offer/${offerId}/make-default`, {}, errorHandler);
  }
  getOfferById(offerId: number, errorHandler: any = undefined) {
    return this.get(`offer/${offerId}`, {}, errorHandler);
  }
  createOffer(offerObject: OfferCreationDTO, errorHandler: any = undefined) {
    return this.post('offer', offerObject, errorHandler);
  }

  cloneOffer(offerId: number, errorHandler: any = undefined) {
    return this.put(`offer/${offerId}/clone`, {}, errorHandler);
  }

  setOfferStatus(
    offerId: number,
    status: OfferStausEnum,
    errorHandler: any = undefined
  ) {
    return this.put(`offer/${offerId}/status`, { status }, errorHandler);
  }

  deleteOffer(offerId: number, force = false, errorHandler: any = undefined) {
    return this.delete(`offer/${offerId}`, {}, errorHandler);
  }

  getCurrencies(errorHandler: any = undefined) {
    return this.get(`sales-funnel/currencies`, {}, errorHandler);
  }

  // Edit Checkout
  getCheckoutById(offerId: number, errorHandler: any = undefined) {
    return this.get(
      `sales-funnel/offers/checkout/${offerId}`,
      {},
      errorHandler
    );
  }

  updateCheckout(
    offerId: number,
    checkoutObject: any,
    errorHandler: any = undefined
  ) {
    return this.put(
      `sales-funnel/offers/checkout/${offerId}`,
      checkoutObject,
      errorHandler
    );
  }

  // Course Logic
  createCourse(
    courseObject: CourseCreationInput,
    errorHandler: any = undefined
  ) {
    return this.post('course', courseObject, errorHandler);
  }

  getCourseDetailsById(id: number, errorHandler: any = undefined) {
    return this.get(`product/${id}/course/details`, {}, errorHandler);
  }

  getCourseContentById(id: number, errorHandler: any = undefined) {
    return this.get(`product/${id}/course/content`, {}, errorHandler);
  }

  getCourseStudentsById(id: number, errorHandler: any = undefined) {
    return this.get(`course/${id}/students`, {}, errorHandler);
  }

  getCourseReports(
    productId: number,
    detailed: boolean = false,
    // filter: BillingLogFilter = null,
    errorHandler: any = undefined
  ) {
    let queryStructure = '?';
    if (detailed) {
      queryStructure = queryStructure + 'detailed=true';
    }

    // if (!!filter?.type) {
    //   filterStructure = filterStructure + '&';
    //   filterStructure = filterStructure + `type=${filter.type}`;
    // }
    // if (!!filter?.page) {
    //   filterStructure = filterStructure + '&';
    //   filterStructure = filterStructure + `page=${filter.page}`;
    // }

    // if (!!filter?.itemsPerPage) {
    //   filterStructure = filterStructure + '&';
    //   filterStructure = filterStructure + `itemsPerPage=${filter.itemsPerPage}`;
    // }

    return this.post(
      `course/${productId}/reports/progress` +
        (!!queryStructure && queryStructure != '?' ? queryStructure : ``),
      {},
      errorHandler
    );
  }

  updateCourseSettingsById(
    id: number,
    payload: CourseSettingsModel,
    errorHandler: any = undefined
  ) {
    return this.post(`course/${id}/settings`, payload, errorHandler);
  }

  updateCourseExtraMetaById(
    id: number,
    payload: Partial<CourseDetailsModelBase>,
    errorHandler: any = undefined
  ) {
    return this.post(`course/${id}/extra-metas`, payload, errorHandler);
  }

  updateCourseCategoryById(
    id: number,
    payload: CategoryUpdateDto,
    errorHandler: any = undefined
  ) {
    return this.post(`course/${id}/categories`, payload, errorHandler);
  }

  updateCourseInstructorsById(
    id: number,
    payload: InstructorsUpdateDto,
    errorHandler: any = undefined
  ) {
    return this.post(`course/${id}/instructors`, payload, errorHandler);
  }

  // Student Monitoring
  getCourseQuizResults(
    courseId: number,
    quizId: number,
    errorHandler: any = undefined
  ) {
    return this.get(
      `course/${courseId}/quiz/${quizId}/results`,
      {},
      errorHandler
    );
  }

  getLockByList(
    courseId: number,
    payload: { id: number; itemType: CourseHierarchyItemTypeEnum },
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/get-lock-items`,
      payload,
      errorHandler
    );
  }

  moveCourseitem(
    id: number,
    payload: MoveCourseItemInput,
    errorHandler: any = undefined
  ) {
    return this.put(`course/${id}/move-item`, payload, errorHandler);
  }

  setLessonAsPreview(
    id: number,
    payload: SetLessonAsPreviewInput,
    errorHandler: any = undefined
  ) {
    return this.put(`course/${id}/set-sample`, payload, errorHandler);
  }

  changeCourseItemStatus(
    id: number,
    payload: ChangeCourseItemStatusInput,
    errorHandler: any = undefined
  ) {
    return this.post(`course/${id}/change-status`, payload, errorHandler);
  }

  deleteCourseItem(
    courseId: number,
    payload: DeleteCourseItemInput,
    errorHandler: any = undefined
  ) {
    return this.delete(`course/${courseId}/item`, payload, errorHandler);
  }

  applyTemplateToCourse(
    courseId: number,
    setCourseHeirarchyInput: SetCourseHeirarchyInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/set-hierarchy`,
      setCourseHeirarchyInput,
      errorHandler
    );
  }

  // Course Items Add

  genericAddCourseItem(
    courseId: number,
    itemType: CourseHierarchyItemTypeEnum = CourseHierarchyItemTypeEnum.module,
    payload: GenericCourseItemCreationInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/${itemType.toLowerCase()}`,
      payload,
      errorHandler
    );
  }
  addCourseModule(
    courseId: number,
    moduleCreationInput: CreateModuleInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/module`,
      moduleCreationInput,
      errorHandler
    );
  }

  addCourseSubModule(
    courseId: number,
    subModuleCreationInput: CreateSubModuleInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/submodule`,
      subModuleCreationInput,
      errorHandler
    );
  }

  addCourseLesson(
    courseId: number,
    lessonCreationInput: CreateLessonInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/lesson`,
      lessonCreationInput,
      errorHandler
    );
  }

  // Assignment
  addCourseAssignment(
    courseId: number,
    assignmentCreationInput: CreateAssignmentInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/assignment`,
      assignmentCreationInput,
      errorHandler
    );
  }

  getCourseAssignment(
    courseId: number,
    assignmentId: number,
    errorHandler: any = undefined
  ) {
    return this.get(
      `course/${courseId}/assignment/${assignmentId}`,
      errorHandler
    );
  }

  // Quiz
  getCourseQuiz(
    courseId: number,
    quizId: number,
    errorHandler: any = undefined
  ) {
    return this.get(`course/${courseId}/quiz/${quizId}`, errorHandler);
  }

  addCourseQuiz(
    courseId: number,
    quizCreationInput: CreateQuizInput,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/quiz`,
      quizCreationInput,
      errorHandler
    );
  }

  addBulkQuizQuestions(
    courseId: number,
    quizId: number,
    questionsInput: CourseQuizQuestionItem[],
    override: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/question/bulk/${courseId}/quiz/${quizId}`,
      { questions: questionsInput, override },
      errorHandler
    );
  }

  addQuizQuestion(
    courseId: number,
    quizId: number,
    questionCreationInput: CourseQuizQuestionItem,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/quiz/${quizId}/questions`,
      { questions: [questionCreationInput] },
      errorHandler
    );
  }

  moveQuizQuestion(
    id: number,
    courseId: number,
    quizId: number,
    payload: MoveQuizQuestionItemInput,
    errorHandler: any = undefined
  ) {
    return this.put(
      `course/${courseId}/quiz/${quizId}/questions/${id}`,
      payload,
      errorHandler
    );
  }

  updateQuizQuestion(
    courseId: number,
    quizId: number,
    questionId: number,
    questionUpdatePayload: CourseQuizQuestionItem,
    errorHandler: any = undefined
  ) {
    return this.post(
      `course/${courseId}/quiz/${quizId}/questions/${questionId}`,
      questionUpdatePayload,
      errorHandler
    );
  }

  deleteQuizQuestion(
    courseId: number,
    quizId: number,
    questionId: number,
    errorHandler: any = undefined
  ) {
    return this.delete(
      `course/${courseId}/quiz/${quizId}/questions`,
      { questionIds: [questionId] },
      errorHandler
    );
  }

  genericGetCourseItem(
    courseId: number,
    itemId: number,
    itemType: CourseHierarchyItemTypeEnum = CourseHierarchyItemTypeEnum.module,
    errorHandler: any = undefined
  ) {
    return this.get(
      `course/${courseId}/${itemType.toLowerCase()}/${itemId}`,
      {},
      errorHandler
    );
  }

  getCourseLesson(
    courseId: number,
    lessonId: number,
    errorHandler: any = undefined
  ) {
    return this.get(`course/${courseId}/lesson/${lessonId}`, errorHandler);
  }

  getCourseModule(
    courseId: number,
    moduleId: number,
    errorHandler: any = undefined
  ) {
    return this.get(`course/${courseId}/module/${moduleId}`, errorHandler);
  }

  getCourseSubModule(
    courseId: number,
    subModuleId: number,
    errorHandler: any = undefined
  ) {
    return this.get(
      `course/${courseId}/submodule/${subModuleId}`,
      errorHandler
    );
  }

  genericUpdateCourseItem(
    courseId: number,
    itemId: number,
    itemType: CourseHierarchyItemTypeEnum = CourseHierarchyItemTypeEnum.module,
    payload:
      | ModuleEditInput
      | SubModuleEditInput
      | LessonEditInput
      | AssignmentEditInput
      | QuizEditInput,
    errorHandler: any = undefined
  ) {
    return this.put(
      `course/${courseId}/${itemType.toLowerCase()}/${itemId}`,
      payload,
      errorHandler
    );
  }
  updateCourseLesson(
    courseId: number,
    lessonId: number,
    data: LessonEditInput,
    errorHandler: any = undefined
  ) {
    return this.put(
      `course/${courseId}/lesson/${lessonId}`,
      data,
      errorHandler
    );
  }

  updateCourseQuiz(
    courseId: number,
    quizId: number,
    data: QuizEditInput,
    errorHandler: any = undefined
  ) {
    return this.put(`course/${courseId}/quiz/${quizId}`, data, errorHandler);
  }

  updateCourseAssignment(
    courseId: number,
    assignmentId: number,
    data: AssignmentEditInput,
    errorHandler: any = undefined
  ) {
    return this.put(
      `course/${courseId}/assignment/${assignmentId}`,
      data,
      errorHandler
    );
  }

  updateCourseModule(
    courseId: number,
    moduleId: number,
    data: ModuleEditInput,
    errorHandler: any = undefined
  ) {
    return this.put(
      `course/${courseId}/module/${moduleId}`,
      data,
      errorHandler
    );
  }

  updateCourseSubModule(
    courseId: number,
    subModuleId: number,
    data: SubModuleEditInput,
    errorHandler: any = undefined
  ) {
    return this.put(
      `course/${courseId}/submodule/${subModuleId}`,
      data,
      errorHandler
    );
  }

  // Product

  createProduct(
    productObject: ProductCreateModel,
    errorHandler: any = undefined
  ) {
    return this.post('sales-funnel/products', productObject, errorHandler);
  }

  cloneProduct(productId: number, errorHandler: any = undefined) {
    return this.post(`sales-funnel/products/${productId}`, errorHandler);
  }

  updateProduct(
    productId: number,
    payload: Partial<ProductItem>,
    errorHandler: any = undefined
  ) {
    return this.put(`product/${productId}`, payload, errorHandler);
  }

  deleteProduct(
    productId: number,
    force = false,
    errorHandler: any = undefined
  ) {
    return this.delete(
      `sales-funnel/products/${productId}?force=${force}`,
      errorHandler
    );
  }

  getProducts(errorHandler: any = undefined) {
    return this.get(`sales-funnel/products`, {}, errorHandler);
  }
  getProductById(productId: number, errorHandler: any = undefined) {
    return this.get(`product/${productId}`, {}, errorHandler);
  }
  getProductsTypes(errorHandler: any = undefined) {
    return this.get(`sales-funnel/products/types`, {}, errorHandler);
  }

  // Media Library
  addMediaFile(
    formData: any,
    category: 'content' | 'media' | 'any',
    errorHandler: any = undefined
  ): Observable<any> {
    // return this.post(`sales-funnel/media/${category}`, [formData], errorHandler);
    return this.upload(
      `sales-funnel/media/${category}`,
      formData,
      errorHandler
    );
  }

  getAllMedia(
    category: MediaLibraryCategoryEnum,
    errorHandler: any = undefined
  ) {
    let query = !!category?.length ? `?category=${category}` : '';

    return this.get(`sales-funnel/media${query}`, errorHandler);
    //
  }

  deleteMediaById(itemId: number, errorHandler: any = undefined) {
    return this.delete(`sales-funnel/media/${itemId}`, {}, errorHandler);
  }
  getMediaById(itemId: number, errorHandler: any = undefined) {
    return this.get(`sales-funnel/media/${itemId}`, {}, errorHandler);
  }
  getMediaTypes(errorHandler: any = undefined) {
    return this.get(`sales-funnel/media/types`, {}, errorHandler);
  }

  getMediaLibraryQuota(errorHandler: any = undefined) {
    return this.get(`users/quota/storage`, {}, errorHandler);
  }
  // Sales Funnel Calls End

  checkPortalName(portalName: string, errorHandler: any = undefined) {
    return this.get(
      `portal/user/check-portal-name/${portalName}`,
      {},
      errorHandler
    );
  }

  getUserDomains(errorHandler: any = undefined) {
    return this.get('portal/user/domains', {}, errorHandler);
  }

  me(errorHandler: any = undefined) {
    return this.get(`appwrite/me`, {}, errorHandler);
  }

  getSubscriptionInfo(errorHandler: any = undefined) {
    return this.get('users/subscription-info', {}, errorHandler);
  }

  getSubscriptionBundleInfo(errorHandler: any = undefined) {
    return this.get('subscription/get-subscription-info', {}, errorHandler);
  }

  buyDomain(domain: string, tld: string, errorHandler: any = undefined) {
    return this.post(
      'namecheap/create',
      {
        domainName: domain,
        tld,
        years: 1,
        addFreeWhoisguard: 'yes',
        wGEnabled: 'yes',
      },
      errorHandler
    );
  }

  setHost(sld: string, tld: string, errorHandler: any = undefined) {
    this.post(
      'namecheap/set-domain-host',
      {
        sld,
        tld,
      },
      errorHandler
    );
  }

  screenshot(url: string, fullPage: boolean, errorHandler: any = undefined) {
    url = encodeURIComponent(url);
    return this.getScreenshot(
      'screen-shot?url=' + url + '&fullPage=' + fullPage,
      {},
      errorHandler
    );
  }

  // Domains
  getDomainsDetails(
    excludePortalId: number = null,
    errorHandler: any = undefined
  ) {
    return this.get(
      'portal/user/domains-details?' +
        `${!!excludePortalId ? 'excludePortal=' + excludePortalId : ''}`,
      {},
      errorHandler
    );
  }

  getDomainDetailsById(id: number, errorHandler: any = undefined) {
    return this.get(`portal/user/domains-details/${id}`, {}, errorHandler);
  }

  linkDomainToPortal(
    domainId: number,
    portalId: number,
    isDefault: boolean = false,
    errorHandler: any = undefined
  ) {
    return this.post(
      `portal/user/link-unlink-domain/${portalId}`,
      {
        domainId,
        link: true,
        isDefault,
      },
      errorHandler
    );
  }

  unlinkDomainToPortal(
    domainId: number,
    portalId: number,
    errorHandler: any = undefined
  ) {
    return this.post(
      `portal/user/link-unlink-domain/${portalId}`,
      {
        domainId,
        link: false,
      },
      errorHandler
    );
  }

  setWebsitedentity(
    data: SetWebsiteIdentityRequestBody | SetWebsiteBrandingRequestBody,
    errorHandler: any = undefined
  ) {
    return this.post(`website/identity`, data, errorHandler);
  }

  changePortalSubdomain(
    portalId: number,
    domain: string,
    errorHandler: any = undefined
  ) {
    return this.put(
      `portal/user/subdomain/${portalId}`,
      { domain },
      errorHandler
    );
  }

  resendVerificationEmail(errorHandler: any = undefined) {
    return this.get('auth/resend-verification-email', {}, errorHandler);
  }

  getCountries(errorHandler: any = undefined) {
    return this.get('location/countries', errorHandler);
  }

  getStates(countryId: number, errorHandler: any = undefined) {
    return this.get(`location/countries/${countryId}/states`, {}, errorHandler);
  }

  resendEmail(sc: string, errorHandler: any = undefined) {
    return this.get(`auth/resend-email/${sc}`, {}, errorHandler);
  }

  uploadProfileImage(
    data: string,
    extension: string,
    errorHandler: any = undefined
  ) {
    return this.put(
      'avatar',
      {
        data,
        extension,
      },
      errorHandler
    );
  }

  passwordRecoveryRequest(email: string, errorHandler: any = undefined) {
    return this.post(`users/reset-password/${email}`, {}, errorHandler);
  }

  passwordRecoveryConfirmation(
    secret: string,
    password: string,
    errorHandler: any = undefined
  ) {
    return this.post(
      'users/reset-password-confirm',
      {
        secret,
        password,
      },
      errorHandler
    );
  }

  cancelSubscription(reason: string, errorHandler: any = undefined) {
    return this.post(
      `subscription/cancel-subscription`,
      {
        reason,
      },
      errorHandler
    );
  }

  resetPasswordRequest(
    userEmail: string,
    sc: string,
    userPassword: string,
    errorHandler: any = undefined
  ) {
    return this.post(
      'users/reset-password-request',
      {
        userEmail,
        sc,
        userPassword,
      },
      errorHandler
    );
  }

  changePasswordRequest(
    payload: {
      currentPassword: string;
      newPassword: string;
      confirmPassword: string;
    },
    errorHandler: any = undefined
  ) {
    return this.post('users/change-password', payload, errorHandler);
  }

  changeEmailRequest(
    payload: { password: string; email: string },
    errorHandler: any = undefined
  ) {
    return this.post('users/update-email', payload, errorHandler);
  }

  getDefaultAvatarList(errorHandler: any = undefined) {
    return this.get('avatar/default-avatar-list', {}, errorHandler);
  }

  chooseDefaultAvatar(avatarId: number, errorHandler: any = undefined) {
    return this.post(
      'avatar/default-avatar',
      {
        id: avatarId,
      },
      errorHandler
    );
  }

  getMailServices(errorHandler: any = undefined) {
    return this.get('users/mail-services', {}, errorHandler);
  }

  getUserMailServices(errorHandler: any = undefined) {
    return this.get('users/user-mail-services', {}, errorHandler);
  }

  changePageStatus(
    pageId: number,
    status: PageItemStatusEnum,
    errorHandler: any = undefined
  ) {
    return this.put(
      `website/pages/status/${pageId}`,
      {
        status,
      },
      errorHandler
    );
  }

  deletePage(pageId: number, errorHandler: any = undefined) {
    return this.delete(
      'website/pages',
      {
        pageId,
      },
      errorHandler
    );
  }

  createNewPage(title: string, errorHandler: any = undefined) {
    return this.post(
      'website/pages',
      {
        title,
      },
      errorHandler
    );
  }

  setUserMailServices(
    mailSeriveId: number,
    apiKey: string,
    errorHandler: any = undefined
  ) {
    return this.post(
      'users/set-mail-service',
      {
        mailSeriveId,
        apiKey,
      },
      errorHandler
    );
  }

  updatePortalMail(
    portalId: number,
    mailServiceId: number,
    email: string,
    errorHandler: any = undefined
  ) {
    return this.post(
      'portal/user/update-portal-mail',
      { portalId, mailServiceId, email },
      errorHandler
    );
  }

  doPayment(
    paymentType: 'stripe' | 'btcpay',
    purchaseType: 'domain' | 'subscription',
    coinType: 'BTC' | '' | undefined = undefined,
    couponId: number | undefined = undefined,
    bundleId: number | undefined = undefined,
    amountInUsd?: number | undefined,
    domain?: string | undefined,
    paymentMethodId?: string | undefined,
    errorHandler: any = undefined
  ) {
    return this.post(
      'payment/do-payment',
      {
        amountInUsd,
        domain,
        paymentType,
        purchaseType,
        couponId,
        coinType,
        bundleId,
        paymentMethodId,
      },
      errorHandler
    );
  }

  // oneTimeSessionPaymentStripe(amountInUsd: number, domain: string, errorHandler: any = undefined) {
  //   return this.post('payment/one-time-payment-session-stripe', {
  //     amountInUsd, domain
  //   }, errorHandler);
  // }
  // oneTimeSessionPaymentBtcpay(amountInUsd: number, domain: string, errorHandler: any = undefined) {
  //   return this.post('payment/one-time-payment-session-btcpay', {
  //     amountInUsd, domain
  //   }, errorHandler);
  // }

  checkSubdomainAvailability(
    portalId: number,
    domain: string,
    errorHandler: any = undefined
  ) {
    return this.post(
      `portal/user/subdomain-exists/${portalId}`,
      { domain },
      errorHandler
    );
  }

  checkDomainIfExist(domain: string, errorHandler: any = undefined) {
    return this.get('portal/user/check-domain/' + domain, {}, errorHandler);
  }

  checkTransictionStatus(transictionID: string, errorHandler: any = undefined) {
    return this.get(
      'payment/check-transaction-status/' + transictionID,
      {},
      errorHandler
    );
  }

  getWebsitePages(errorHandler: any = undefined) {
    return this.get(`website/pages`, {}, errorHandler);
  }

  // Website Blog
  deleteWebsiteBlogPost(postId: any, errorHandler: any = undefined) {
    return this.delete(`website/blog/posts/${postId}`, errorHandler);
  }

  updateWebsiteBlogPost(
    postId: number,
    payload: Partial<CreateOrUpdateBlogPostDTO>,
    errorHandler: any = undefined
  ) {
    return this.put(`website/blog/posts/${postId}`, payload, errorHandler);
  }

  updatePostStatus(
    postId: number,
    status: BlogItemStatusEnum,
    errorHandler: any = undefined
  ) {
    return this.put(
      `website/blog/posts/${postId}`,
      {
        status,
      },
      errorHandler
    );
  }
  getWebsiteBlog(errorHandler: any = undefined) {
    return this.get(`website/blog/posts`, {}, errorHandler);
  }

  createWebsiteBlogPost(
    payload: CreateOrUpdateBlogPostDTO,
    errorHandler: any = undefined
  ) {
    return this.post(`website/blog/posts`, payload, errorHandler);
  }

  getWebsiteAggregateAnalytics(errorHandler: any = undefined) {
    return this.callPlausible(
      `analytics/website/aggregate?metrics=visits,visitors,pageviews,bounce_rate,visit_duration`,
      null,
      errorHandler
    );
  }

  // getWebsiteAggregateAnalytics(errorHandler: any = undefined) {
  //   return this.get(`analytics/website/aggregate?metrics=visits,visitors,pageviews,bounce_rate,visit_duration`, {}, errorHandler);
  // }

  getWebsiteTrafficSources(errorHandler: any = undefined) {
    return this.callPlausible(
      `analytics/website/aggregate?metrics=visits,visitors,pageviews,bounce_rate,visit_duration`,
      null,
      errorHandler
    );
  }

  getWebsiteBestTrafficCountry(errorHandler: any = undefined) {
    return this.callPlausible(
      `analytics/website/breakdown?type=visit&property=country`,
      { limit: 1, period: 'day' },
      errorHandler
    );
  }

  getWebsiteRealtimeCurrentVisitors(
    errorHandler: any = undefined
  ): Observable<number> {
    return this.callPlausible(`analytics/website/realtime`, null, errorHandler);
  }

  callPlausible(
    url: string,
    filters: { period?: PeriodTypeModel | string; limit?: number },
    errorHandler: any = undefined
  ) {
    const { period, limit } = filters || {period: null, limit: null};

    let periodQueryStructure = url.includes('?') ? '&' : '?';

    if (!!period) {
      if (period instanceof PeriodTypeModel) {
        if (!!period?.value?.startDate) {
          periodQueryStructure += `&startDate=${period.value.startDate}`;
        }
        if (!!period?.value?.endDate) {
          periodQueryStructure += `&endDate=${period.value.endDate}`;
        }
      }

      if (typeof period === 'string') {
        periodQueryStructure += `&period=${period}`;
      }
    }

    if (!!limit) {
      periodQueryStructure += `&limit=${limit}`;
    }

    return this.get(
      `${url}${periodQueryStructure.length > 1 ? periodQueryStructure : ``}`,
      {},
      errorHandler
    );
  }

  // https://analytics.learmo.dev/api/stats/learmo.dev/sources?period=all&date=2024-08-22&filters=%7B%7D&with_imported=true

  getWebsiteCategories(errorHandler: any = undefined) {
    return this.get(`website/categories`, {}, errorHandler);
  }

  createWebsiteCategories(
    payload: { name: string },
    errorHandler: any = undefined
  ) {
    return this.post(`website/categories`, payload, errorHandler);
  }

  getWebsiteInstructors(errorHandler: any = undefined) {
    return this.get(`website/instructors`, {}, errorHandler);
  }

  // createWebsiteInstructors(payload: {name: string},errorHandler: any = undefined) {
  //   return this.post(`website/instructors`, payload, errorHandler);
  // }

  getNamecheapBalance(errorHandler: any = undefined) {
    return this.get('namecheap/check-balance', {}, errorHandler);
  }

  getUpgradeCalculations(
    upgradeBundleID: number,
    errorHandler: any = undefined
  ) {
    return this.get(
      'subscription/calculate-change-plan-fees/' + upgradeBundleID,
      {},
      errorHandler
    );
  }

  getBtcRate(rateTypes: RateTypeEnum[], errorHandler: any = undefined) {
    return this.post(
      'portal/user/btc-rate',
      {
        rateTypes,
      },
      errorHandler
    );
  }

  getPlans(errorHandler: any = undefined) {
    return this.get('subscription/bundles', {}, errorHandler);
  }

  getPlansAuth(errorHandler: any = undefined) {
    return this.get('subscription/bundles/auth', {}, errorHandler);
  }

  firstPortal(errorHandler: any = undefined) {
    return this.get('portal/user/check-first-website', {}, errorHandler);
  }

  getPortalVideos(errorHandler: any = undefined) {
    return this.get('portal/user/videos', {}, errorHandler);
  }

  addPaymentMethod(
    errorHandler: any = undefined
  ) {
    return this.post(
      'payment/add-payment-method',
      {},
      // {
      //   bundleId,
      //   couponId,
      //   isRegister,
      // },
      errorHandler
    );
  }

  setDefaultPayment(id: string, errorHandler: any = undefined) {
    return this.post('payment/default-payment-method/' + id, {}, errorHandler);
  }

  resumeMyAccount(errorHandler: any = undefined) {
    return this.post('subscription/resume-subscription', {}, errorHandler);
  }

  deletePaymentMethod(id: string, errorHandler: any = undefined) {
    return this.delete('payment/payment-method/' + id, {}, errorHandler);
  }

  getAllPaymentMethod(sort: boolean = true, errorHandler: any = undefined) {
    return this.get(
      'payment/payment-method/' + sort.toString(),
      {},
      errorHandler
    );
  }

  finishUserTour(errorHandler: any = undefined) {
    return this.post('users/finish-tour', {}, errorHandler);
  }

  convertUsdToBtc(priceInUsd: number, errorHandler: any = undefined) {
    return this.post(`payment/usd-to-btc/${priceInUsd}`, {}, errorHandler);
  }

  getCountOfSubscriptionPermissionsDomains(errorHandler: any = undefined) {
    return this.get('subscription/num-of-domains', {}, errorHandler);
  }

  getCountOfSubscriptionPermissionsPortalsAndDomains(
    errorHandler: any = undefined
  ) {
    return this.get(
      'subscription/num-of-portals-and-domains',
      {},
      errorHandler
    );
  }

  getCountOfSubscriptionPermissionsPortals(errorHandler: any = undefined) {
    return this.get('subscription/num-of-portals', {}, errorHandler);
  }

  private post(path: string, params: any, errorHandler: any = undefined) {
    return this.call('post', path, params, errorHandler);
  }

  private patch(path: string, params: any, errorHandler: any = undefined) {
    return this.call('patch', path, params, errorHandler);
  }

  private get(path: string, params: any, errorHandler: any = undefined) {
    return this.call('get', path, params, errorHandler);
  }

  private getScreenshot(
    path: string,
    params: any,
    errorHandler: any = undefined
  ) {
    return this.callScreenshot('get', path, params, errorHandler);
  }

  private put(path: string, params: any, errorHandler: any = undefined) {
    return this.call('put', path, params, errorHandler);
  }

  private delete(path: string, params: any, errorHandler: any = undefined) {
    return this.call('delete', path, params, errorHandler);
  }

  private download(
    path: string,
    params: any = undefined,
    errorHandler: any = undefined
  ) {
    if (!errorHandler) errorHandler = this.handleError;
    return this.http
      .get<any>(`${this.apiUrl}/${path}`, {
        params,
        headers: this.headers,
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(errorHandler));
  }

  public upload(path: string, formData, errorHandler: any = undefined) {
    if (!errorHandler) errorHandler = this.handleError;

    return this.http
      .request<any>('post', `${this.apiUrl}/${path}`, {
        body: formData,
      })
      .pipe(
        map((resp) => {
          if (resp?.error) {
            console.error('LSG API Error', resp);
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }

  call(method, path: string, params: any, errorHandler: any = undefined) {
    if (!errorHandler) errorHandler = this.handleError;
    // console.debug(`LSG API request '${method}'`, `${ this.apiUrl }/${ path }`, params);
    return this.http
      .request<any>(method, `${this.apiUrl}/${path}`, {
        body: params,
        headers: this.headers,
      })
      .pipe(
        map((resp) => {
          if (resp?.error) {
            console.error('LSG API Error', resp);
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }

  freeCall(method, url: string, params: any, errorHandler: any = undefined) {
    if (!errorHandler) errorHandler = this.handleError;
    // console.debug(`LSG API request '${method}'`, `${ this.apiUrl }/${ path }`, params);
    return this.http
      .request<any>(method, `${url}`, {
        body: params,
        headers: this.headers,
      })
      .pipe(
        map((resp) => {
          if (resp?.error) {
            console.error('LSG API Error', resp);
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }

  private requestErrorAlert = (error: any) => {
    Swal.fire({
      title: 'Oops, Something went wrong!',
      html: `
      learmo has encountered an error. If this problem persists,
      contact us at <a href="mailto:help@learmo.com">help@learmo.com</a>
      <ul class="text-start mt-4 mb-0">
        <li><strong>Message Head:</strong> ${error.message}</li>
        <li><strong>Error Message:</strong> ${error.error}</li>
        <li><strong>Code:</strong> ${error.statusCode}</li>
      </ul>
      `,
      confirmButtonText: 'Go To Home',
      allowOutsideClick: true,
      allowEscapeKey: true,
      showCancelButton: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger',
      },
      icon: 'error',
    }).then((result) => {
      if (result.isConfirmed) {
        document.location.href = environment.basePath;
      }
    });
  };

  silentHttpErrorReport = (error: HttpErrorResponse) => {
    this.crashReportsService.silentGleapHttpErrorReport(error);
  }
  private handleError = (error: HttpErrorResponse) => {
    console.error('API SERVICE ERROR: ', error);
    this.silentHttpErrorReport(error);
    // if (!environment?.production && !!environment.appSettings?.errors?.displayPopAlert) {
    this.requestErrorAlert(error);
    // }
    return throwError(() => error);
  };

  callScreenshot(
    method,
    path: string,
    params: any,
    errorHandler: any = undefined
  ) {
    if (!errorHandler) errorHandler = this.handleError;
    // console.debug(`LSG API request '${method}'`, `${ this.apiUrl }/${ path }`, params);

    return this.http
      .request<any>(method, `${this.apiUrl}/${path}`, {
        body: params,
        headers: this.headers,
      })
      .pipe(
        map((resp) => {
          if (resp?.error) {
            throw resp?.error;
          }
          return resp;
        }),
        catchError(errorHandler)
      );
  }
}
