
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { colorSet } from 'src/app/models/global-classes';

export enum DropdownItemActionTypeEnum {
  normal = 'normal',
  portal = 'portal'
}
export class DropdownItem {
  label: string;
  iconSvg?: string;
  type?: DropdownItemActionTypeEnum;
  colorSet?: colorSet = 'gray-700';
  colorSetHover?: colorSet = 'primary';
  action?: () => void | Promise<void>;
  routerLink?: string | any[];
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename' | undefined;
  disabled?: boolean;
  forceHide?: boolean;
  isActive?: boolean = false

  constructor(values?: DropdownItem) {
    Object.assign(this, values)

  }
}
@Component({
  selector: 'app-dynamic-dropdown',
  templateUrl: './dynamic-dropdown.component.html',
  styleUrls: ['./dynamic-dropdown.component.css']
})
export class DynamicDropdownComponent implements OnInit {
  dropdownItemActionTypeEnum = DropdownItemActionTypeEnum
  @Output() DropdownEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() dropdownItems: DropdownItem[] = [

  ]
  @Input() hoverTrigger: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

  onDropdownEvent(value: any) {
    this.DropdownEvent.emit(value);
    
  }

}
